import React from 'react';

const removeUserData = () => (
  <main style={{ margin: '10rem 2rem', 'min-height': '100vh' }}>
    <h1 style={{ 'font-size': '3.25rem', 'font-weight': 700, margin: '2rem 0' }}>Instrukcja usunięcia danych użytkownika z bazy danych</h1>
    <ol style={{ 'margin-left': '1.75rem', 'list-style': 'decimal' }}>
      <li style={{ 'margin-bottom': '0.5rem' }}>Zaloguj się do aplikacji</li>
      <li style={{ 'margin-bottom': '0.5rem' }}>Kliknij avatar w prawym górnym rogu</li>
      <li style={{ 'margin-bottom': '0.5rem' }}>Wybierz "opcje"</li>
      <li style={{ 'margin-bottom': '0.5rem' }}>Kliknij przycisk "usuń konto"</li>
      <li style={{ 'margin-bottom': '0.5rem' }}>Zatwierdź, klikając "OK"</li>
    </ol>
    <h1 style={{ 'font-size': '3.25rem', 'font-weight': 700, margin: '2rem 0' }}>How to delete user data from database</h1>
    <ol style={{ 'margin-left': '1.75rem', 'list-style': 'decimal' }}>
      <li style={{ 'margin-bottom': '0.5rem' }}>Login to application</li>
      <li style={{ 'margin-bottom': '0.5rem' }}>Click on avatar in top right corner</li>
      <li style={{ 'margin-bottom': '0.5rem' }}>Pick "opcje"</li>
      <li style={{ 'margin-bottom': '0.5rem' }}>Click on "usuń konto"</li>
      <li style={{ 'margin-bottom': '0.5rem' }}>Accept by clicking "OK"</li>
    </ol>
  </main>
);

export default removeUserData;
